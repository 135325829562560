import * as React from "react";
import { graphql } from "gatsby";
import { Constants } from "../@types/Constants";
import IParagraph from "../@types/IParagraph";
import { IOffersProps } from "../pageHelpers/Offers/OffersProps";
import Layout from "../components/global/Layout/Layout";
import { getParagraph } from "../utils/paragraphHelpers";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import SEO from "../components/global/SEO/SEO";


const Offers = (props: IOffersProps) => {
  const _page = props.data.page;
  const _paragraphs = _page.relationships.paragraphs.map(getParagraph);
  const _pageBanner = _paragraphs.filter((p:IParagraph) => { return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER : false; });
  return (<Layout>
            <SEO title={_page?.title} description={_page?.field_meta_description} />
            {_pageBanner}
            <ParagraphLayout {..._paragraphs} />
          </Layout>);
};
export const pageQuery = graphql`
  query OffersQuery {
    page: nodePage(path: {alias: {eq: "/v1/offers"}}) {
      id
      title
      field_meta_description
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphSiteOffers
          ...ParagraphMediaCta
        }
      }
    }
  }
`;
export default Offers;